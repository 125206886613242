export const vortlisto =
  [
    "abon-",
    "aĉet-",
    "adres-",
    "aer-",
    "afabl-",
    "afer-",
    "afiŝ-",
    "ag-",
    "agrabl-",
    "aĝ-",
    "ajn",
    "akcept-",
    "akr-",
    "aktiv-",
    "akv-",
    "al",
    "alfabet-",
    "ali-",
    "almenaŭ",
    "alt-",
    "am-",
    "amas-",
    "ambaŭ-",
    "amik-",
    "amuz-",
    "angul-",
    "anim-",
    "ankaŭ-",
    "ankoraŭ",
    "anonc-",
    "anstataŭ",
    "antaŭ",
    "aparat-",
    "apart-",
    "aparten-",
    "apenaŭ",
    "aper-",
    "apud",
    "aranĝ-",
    "arb-",
    "art-",
    "artikol-",
    "asoci-",
    "aspekt-",
    "atak-",
    "atend-",
    "atent-",
    "ating-",
    "aŭ",
    "aŭd-",
    "aŭskult-",
    "(aŭto)bus-",
    "aŭt(omobil)-",
    "aŭtun-",
    "av-",
    "aviad-",
    "babil-",
    "baldaŭ",
    "ban-",
    "bank-",
    "bar-",
    "bat-",
    "batal-",
    "baz-",
    "bedaŭr-",
    "bel-",
    "best-",
    "bezon-",
    "bibliotek-",
    "bicikl-",
    "bier-",
    "bild-",
    "bilet-",
    "bird-",
    "blank-",
    "blog-",
    "blov-",
    "blu-",
    "bol-",
    "bon-",
    "bord-",
    "botel-",
    "bov-",
    "brak-",
    "bril-",
    "bru-",
    "brul-",
    "brun-",
    "brust-",
    "bus-",
    "buŝ-",
    "cel-",
    "centr-",
    "cert-",
    "ceter-",
    "cifer-",
    "cigared-",
    "cirkl-",
    "ĉambr-",
    "ĉapel-",
    "ĉar",
    "ĉarm-",
    "ĉe",
    "ĉef-",
    "ĉemiz-",
    "ĉes-",
    "ĉeval-",
    "ĉi",
    "ĉiel-",
    "ĉirkaŭ-",
    "ĉu",
    "da",
    "danc-",
    "danĝer-",
    "dank-",
    "dat-",
    "daŭr-",
    "de",
    "decid-",
    "defend-",
    "dekstr-",
    "demand-",
    "dens-",
    "dent-",
    "depend-",
    "desegn-",
    "detal-",
    "dev-",
    "dezir-",
    "di-",
    "diferenc-",
    "difin-",
    "dik-",
    "dir-",
    "direkt-",
    "disk-",
    "diskut-",
    "divers-",
    "divid-",
    "do",
    "doktor-",
    "dokument-",
    "dolĉ-",
    "dolor-",
    "dom-",
    "domaĝ-",
    "don-",
    "donac-",
    "dorm-",
    "dors-",
    "dosier-",
    "dub-",
    "dum",
    "dung-",
    "eben-",
    "eĉ",
    "eduk-",
    "edz-",
    "efektiv-",
    "efik-",
    "egal-",
    "ekonomi-",
    "ekran-",
    "ekskurs-",
    "ekspozici-",
    "ekster",
    "ekzamen-",
    "ekzempl-",
    "ekzerc-",
    "ekzist-",
    "el",
    "elekt-",
    "elektr-",
    "emoci-",
    "en",
    "energi-",
    "erar-",
    "esper-",
    "esplor-",
    "esprim-",
    "est-",
    "evolu-",
    "fabrik-",
    "facil-",
    "faden-",
    "fajr-",
    "fak-",
    "fakt-",
    "faktur-",
    "fal-",
    "fam-",
    "famili-",
    "far-",
    "fart-",
    "feliĉ-",
    "fenestr-",
    "fer-",
    "feri-",
    "ferm-",
    "fest-",
    "fiks-",
    "fil-",
    "film-",
    "fin-",
    "fingr-",
    "fiŝ-",
    "flag-",
    "flank-",
    "flav-",
    "flor-",
    "flu-",
    "flug-",
    "foj-",
    "foli-",
    "fond-",
    "font-",
    "for",
    "forges-",
    "fork-",
    "form-",
    "fort-",
    "fos-",
    "fot-",
    "frap-",
    "frat-",
    "fraŭl-",
    "fraz-",
    "fremd-",
    "frenez-",
    "freŝ-",
    "frost-",
    "fru-",
    "frukt-",
    "fulm-",
    "fum-",
    "funkci-",
    "fuŝ-",
    "gaj-",
    "gajn-",
    "gas-",
    "gast-",
    "gazet-",
    "glaci-",
    "glas-",
    "glit-",
    "gramatik-",
    "grand-",
    "gras-",
    "gratul-",
    "grav-",
    "gren-",
    "griz-",
    "grup-",
    "gust-",
    "gvid-",
    "ĝarden-",
    "ĝen-",
    "ĝeneral-",
    "ĝentil-",
    "ĝis",
    "ĝoj-",
    "ĝu-",
    "ĝust-",
    "halt-",
    "har-",
    "haŭt-",
    "hav-",
    "haven-",
    "hejm-",
    "hel-",
    "help-",
    "herb-",
    "hieraŭ",
    "histori-",
    "ho",
    "hodiaŭ",
    "hom-",
    "hor-",
    "horloĝ-",
    "hotel-",
    "hund-",
    "ide-",
    "ideal-",
    "imag-",
    "imit-",
    "industri-",
    "infan-",
    "inform-",
    "insekt-",
    "instru-",
    "instrukci-",
    "insul-",
    "inteligent-",
    "intenc-",
    "inter",
    "interes-",
    "intern-",
    "interpret-",
    "intervju-",
    "invit-",
    "ir-",
    "ja",
    "jak-",
    "jam",
    "jar-",
    "je",
    "jen",
    "jes",
    "juĝ-",
    "jun-",
    "jup-",
    "just-",
    "ĵet-",
    "ĵus",
    "kadr-",
    "kaf-",
    "kaj",
    "kajer-",
    "kalendar-",
    "kalkul-",
    "kamp-",
    "kant-",
    "kap-",
    "kapabl-",
    "kapt-",
    "kar-",
    "kart-",
    "kaŝ-",
    "kat-",
    "kategori-",
    "kaŭz-",
    "kaz-",
    "ke",
    "kelk-",
    "kest-",
    "kilogram-",
    "kilometr-",
    "kin-",
    "kis-",
    "klak-",
    "klar-",
    "klas-",
    "klav-",
    "klin-",
    "klopod-",
    "klub-",
    "knab-",
    "kok-",
    "kol-",
    "kolekt-",
    "koler-",
    "kolor-",
    "komb-",
    "komenc-",
    "koment-",
    "komerc-",
    "komfort-",
    "komitat-",
    "kompar-",
    "kompat-",
    "kompren-",
    "komput-",
    "komun-",
    "komunik-",
    "kon-",
    "koncern-",
    "kondiĉ-",
    "konduk-",
    "konfes-",
    "kongres-",
    "konkret-",
    "konkurs-",
    "konsent-",
    "konserv-",
    "konsider-",
    "konsil-",
    "konsist-",
    "konstant-",
    "konstru-",
    "kontakt-",
    "kontent-",
    "kontraŭ",
    "kontribu-",
    "kontrol-",
    "konven-",
    "konvink-",
    "kopi-",
    "kor-",
    "korekt-",
    "korespond-",
    "korp-",
    "kort-",
    "kost-",
    "kostum-",
    "kotiz-",
    "kovert-",
    "kovr-",
    "krajon-",
    "kre-",
    "kred-",
    "kredit-",
    "kresk-",
    "kri-",
    "krom",
    "kruel-",
    "krur-",
    "kudr-",
    "kuir-",
    "kuk-",
    "kuler-",
    "kulp-",
    "kultur-",
    "kun",
    "kur-",
    "kurac-",
    "kuraĝ-",
    "kurs-",
    "kuŝ-",
    "kutim-",
    "kuz-",
    "kvadrat-",
    "kvalit-",
    "kvankam",
    "kvant-",
    "kvazaŭ",
    "la",
    "labor-",
    "lac-",
    "lag-",
    "lakt-",
    "lamp-",
    "land-",
    "lang-",
    "larĝ-",
    "las-",
    "last-",
    "laŭ",
    "lav-",
    "lecion-",
    "leg-",
    "legom-",
    "leĝ-",
    "lern-",
    "lert-",
    "leter-",
    "lev-",
    "liber-",
    "libr-",
    "lig-",
    "lign-",
    "lim-",
    "lingv-",
    "lini-",
    "list-",
    "lit-",
    "liter-",
    "literatur-",
    "log-",
    "loĝ-",
    "lok-",
    "long-",
    "lud-",
    "lum-",
    "lun-",
    "malgraŭ",
    "man-",
    "manĝ-",
    "manier-",
    "mank-",
    "mantel-",
    "mar-",
    "mark-",
    "marŝ-",
    "mastr-",
    "maŝin-",
    "maten-",
    "material-",
    "mebl-",
    "medi-",
    "mem",
    "membr-",
    "memor-",
    "mend-",
    "mens-",
    "merit-",
    "mesaĝ-",
    "met-",
    "metal-",
    "metod-",
    "metr-",
    "mez-",
    "mezur-",
    "mikrofon-",
    "miks-",
    "milit-",
    "minus",
    "minut-",
    "mir-",
    "modern-",
    "mok-",
    "mol-",
    "moment-",
    "mon-",
    "monat-",
    "mond-",
    "mont-",
    "montr-",
    "morgaŭ",
    "mort-",
    "mov-",
    "mult-",
    "mur-",
    "mus-",
    "muze-",
    "muzik-",
    "naci-",
    "naĝ-",
    "nask-",
    "natur-",
    "naz-",
    "ne",
    "nebul-",
    "neces-",
    "neĝ-",
    "nek",
    "nep-",
    "nepr-",
    "neŭtral-",
    "nev-",
    "nigr-",
    "nivel-",
    "nokt-",
    "nom-",
    "nombr-",
    "nord-",
    "normal-",
    "not-",
    "nov-",
    "nu",
    "nub-",
    "numer-",
    "nun",
    "nur",
    "obe-",
    "objekt-",
    "observ-",
    "odor-",
    "ofic-",
    "oft-",
    "okaz-",
    "okcident-",
    "okul-",
    "okup-",
    "ol",
    "ole-",
    "ond-",
    "onkl-",
    "opini-",
    "or-",
    "ord-",
    "ordinar-",
    "ordon-",
    "orel-",
    "organiz-",
    "orient-",
    "original-",
    "ost-",
    "ov-",
    "pac-",
    "pag-",
    "paĝ-",
    "pak-",
    "pan-",
    "pantalon-",
    "paper-",
    "par-",
    "pardon-",
    "park-",
    "parol-",
    "part-",
    "pas-",
    "past-",
    "paŝ-",
    "patr-",
    "paŭz-",
    "pec-",
    "pen-",
    "pend-",
    "pens-",
    "pentr-",
    "per",
    "perd-",
    "perfekt-",
    "period-",
    "permes-",
    "person-",
    "pet-",
    "pez-",
    "pied-",
    "pik-",
    "pilk-",
    "plaĉ-",
    "plafon-",
    "plan-",
    "plank-",
    "plant-",
    "plast-",
    "plej",
    "plen-",
    "plend-",
    "plezur-",
    "pli",
    "plor-",
    "plu",
    "plum-",
    "plur-",
    "plus",
    "pluv-",
    "po",
    "poem-",
    "poent-",
    "poezi-",
    "polic-",
    "politik-",
    "pont-",
    "popol-",
    "popular-",
    "por",
    "pord-",
    "pork-",
    "port-",
    "posed-",
    "post",
    "postul-",
    "poŝ-",
    "poŝt-",
    "pot-",
    "pov-",
    "praktik-",
    "prav-",
    "precip-",
    "preciz-",
    "prefer-",
    "preĝ-",
    "preleg-",
    "prem-",
    "premi-",
    "pren-",
    "prepar-",
    "pres-",
    "preskaŭ",
    "pret-",
    "preter",
    "prez-",
    "prezent-",
    "prezid-",
    "pri",
    "princip-",
    "printemp-",
    "pro",
    "problem-",
    "produkt-",
    "profesi-",
    "profund-",
    "program-",
    "progres-",
    "projekt-",
    "proksim-",
    "promen-",
    "promes-",
    "propon-",
    "propr-",
    "prov-",
    "prunt-",
    "publik-",
    "pun-",
    "punkt-",
    "pup-",
    "pur-",
    "puŝ-",
    "rad-",
    "radi-",
    "radik-",
    "rajt-",
    "rakont-",
    "rand-",
    "rang-",
    "rapid-",
    "raport-",
    "real-",
    "recenz-",
    "redakt-",
    "redaktor-",
    "reg-",
    "region-",
    "regul-",
    "reĝ-",
    "reklam-",
    "rekomend-",
    "rekt-",
    "religi-",
    "renkont-",
    "respekt-",
    "respond-",
    "rest-",
    "ret-",
    "revu-",
    "rezult-",
    "ricev-",
    "riĉ-",
    "rid-",
    "rigard-",
    "rilat-",
    "rimark-",
    "rimed-",
    "ripet-",
    "ripoz-",
    "river-",
    "riz-",
    "rob-",
    "roman-",
    "romp-",
    "rond-",
    "roz-",
    "rubrik-",
    "ruĝ-",
    "rul-",
    "saĝ-",
    "sak-",
    "sal-",
    "salon-",
    "salt-",
    "salut-",
    "sam-",
    "san-",
    "sang-",
    "sankt-",
    "sat-",
    "sav-",
    "sci-",
    "scienc-",
    "se",
    "sed",
    "seĝ-",
    "sek-",
    "sekretari-",
    "seks-",
    "sekur-",
    "sekv-",
    "semajn-",
    "sen",
    "senc-",
    "send-",
    "sent-",
    "serĉ-",
    "seri-",
    "serioz-",
    "serv-",
    "sezon-",
    "sid-",
    "sign-",
    "signif-",
    "silent-",
    "simil-",
    "simpl-",
    "sinjor-",
    "sistem-",
    "situaci-",
    "skatol-",
    "skrib-",
    "soci-",
    "soif-",
    "sol-",
    "soldat-",
    "solv-",
    "somer-",
    "son-",
    "spac-",
    "spec-",
    "special-",
    "spegul-",
    "spekt-",
    "spert-",
    "spez-",
    "spic-",
    "spir-",
    "spirit-",
    "sport-",
    "staci-",
    "star-",
    "stat-",
    "stel-",
    "strang-",
    "strat-",
    "streĉ-",
    "stud-",
    "student-",
    "stult-",
    "sub",
    "subit-",
    "sud-",
    "sufer-",
    "sufiĉ-",
    "sukces-",
    "suker-",
    "sum-",
    "sun-",
    "super",
    "supoz-",
    "supr-",
    "sur",
    "ŝaf-",
    "ŝajn-",
    "ŝanc-",
    "ŝanĝ-",
    "ŝat-",
    "ŝerc-",
    "ŝip-",
    "ŝir-",
    "ŝlos-",
    "ŝnur-",
    "ŝrank-",
    "ŝtat-",
    "ŝtel-",
    "ŝtof-",
    "ŝton-",
    "ŝtup-",
    "ŝu-",
    "ŝut-",
    "tabl-",
    "tabul-",
    "tag-",
    "tamen",
    "tas-",
    "task-",
    "taŭg-",
    "te-",
    "teatr-",
    "tegment-",
    "teknik-",
    "tekst-",
    "telefon-",
    "teler-",
    "televid-",
    "tem-",
    "temp-",
    "ten-",
    "teori-",
    "ter-",
    "terur-",
    "tim-",
    "tir-",
    "tond-",
    "tra",
    "tradici-",
    "traduk-",
    "traf-",
    "trakt-",
    "tranĉ-",
    "trankvil-",
    "trans",
    "tre",
    "trink-",
    "tro",
    "trov-",
    "tru-",
    "tuj",
    "tuk-",
    "turism-",
    "turist-",
    "turn-",
    "tuŝ-",
    "tut-",
    "universal-",
    "universitat-",
    "urb-",
    "util-",
    "uz-",
    "vagon-",
    "valid-",
    "valor-",
    "varm-",
    "vast-",
    "vek-",
    "ven-",
    "vend-",
    "venk-",
    "vent-",
    "ver-",
    "verd-",
    "verk-",
    "verŝ-",
    "vesper-",
    "vest-",
    "veter-",
    "vetur-",
    "viand-",
    "vic-",
    "vid-",
    "vigl-",
    "vilaĝ-",
    "vin-",
    "vintr-",
    "vir-",
    "virtual-",
    "viŝ-",
    "vitr-",
    "viv-",
    "vizaĝ-",
    "vizit-",
    "voĉ-",
    "voj-",
    "vojaĝ-",
    "vok-",
    "vol-",
    "volv-",
    "vort-",
    "zorg-"
  ];