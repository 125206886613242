export const tabelvortoj = [
  "ĉia",
  "ĉiaj",
  "ĉiajn",
  "ĉiam",
  "ĉiama",
  "ĉiamaj",
  "ĉiamajn",
  "ĉiaman",
  "ĉiame",
  "ĉian",
  "ĉie",
  "ĉien",
  "ĉio",
  "ĉion",
  "ĉiu",
  "ĉiuj",
  "ĉiujn",
  "ĉiun",
  "ia",
  "iaj",
  "iajn",
  "iam",
  "iama",
  "iamaj",
  "iamajn",
  "iaman",
  "ian",
  "ie",
  "iel",
  "ien",
  "io",
  "iom",
  "ioma",
  "iomaj",
  "iomajn",
  "ioman",
  "iome",
  "iomete",
  "ion",
  "iu",
  "iuj",
  "iujn",
  "iun",
  "kia",
  "kiaj",
  "kiajn",
  "kial",
  "kialo ",
  "kialoj",
  "kialojn",
  "kialon",
  "kiam",
  "kian",
  "kie",
  "kiel",
  "kien",
  "kies",
  "kio",
  "kiom",
  "kioma",
  "kiomaj",
  "kiomajn",
  "kioman",
  "kiome",
  "kion",
  "kiu",
  "kiuj",
  "kiujn",
  "kiun",
  "nenia",
  "neniaj",
  "neniajn",
  "neniam",
  "nenian",
  "nenie",
  "neniel",
  "nenien",
  "nenio",
  "neniom",
  "nenion",
  "neniu",
  "neniuj",
  "neniujn",
  "neniun",
  "tia",
  "tiaj",
  "tiajn",
  "tial",
  "tiam",
  "tian",
  "tie",
  "tiea",
  "tiel",
  "tien",
  "ties",
  "tio",
  "tiom",
  "tioma",
  "tiomaj",
  "tiomajn",
  "tioman",
  "tion",
  "tiu",
  "tiuj",
  "tiujn",
  "tiun",
];
