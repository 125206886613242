export const neEnLaListo = [
  "aŭtor-",
  "aŭtorin-",
  "diamant-",
  "ekspluat-",
  "esting-",
  "farĉ-",
  "fontan-",
  "invent-",
  "propagand-",
  "robot-",
  "senat-"
]