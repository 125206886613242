// En la listo enestas du tipoj de vortoj:
//
// - Vortoj finiĝantaj per streketo bezonas vortoklasan finaĵon (-o, -a, -i, -e)
// - Vortoj sen streketoj estas uzeblaj sen finaĵo
//
// Prefiksoj kaj sufiksoj povus esti en la ĉefa listo laŭ tipo 1, sed
// por konveno ili estas listigitaj en apartaj listoj sube.

export const vortaroFacilaj = [
  "abon-",
  "aer-",
  "afabl-",
  "afiŝ-",
  "agrabl-",
  "ajn*",
  "akr-",
  "aktiv-",
  "alfabet-",
  "almenaŭ*",
  "amas-",
  "ambaŭ*",
  "amuz-",
  "angul-",
  "anim-",
  "anonc-",
  "aparat-",
  "aparten-",
  "apenaŭ*",
  "aranĝ-",
  "aspekt-",
  "atak-",
  "atent-",
  "ating-",
  "av-",
  "aviad-",
  "babil-",
  "ban-",
  "bank-",
  "bar-",
  "bat-",
  "batal-",
  "baz-",
  "bedaŭr-",
  "bezon-",
  "bibliotek-",
  "bier-",
  "bilet-",
  "blank-",
  "blog-",
  "blov-",
  "blu-",
  "bol-",
  "bord-",
  "botel-",
  "bov-",
  "brak-",
  "bru-",
  "brul-",
  "brun-",
  "brust-",
  "buŝ-",
  "centr-",
  "ceter-",
  "cifer-",
  "cigared-",
  "cirkl-",
  "ĉapel-",
  "ĉarm-",
  "ĉemiz-",
  "ĉes-",
  "ĉeval-",
  "danc-",
  "danĝer-",
  "dat-",
  "defend-",
  "dens-",
  "dent-",
  "depend-",
  "desegn-",
  "detal-",
  "di-",
  "diferenc-",
  "difin-",
  "disk-",
  "diskut-",
  "divid-",
  "doktor-",
  "dokument-",
  "dolor-",
  "domaĝ-",
  "donac-",
  "dors-",
  "dosier-",
  "dub-",
  "dung-",
  "eben-",
  "efektiv-",
  "efik-",
  "egal-",
  "ekonomi-",
  "ekran-",
  "ekskurs-",
  "ekspozici-",
  "ekzamen-",
  "ekzerc-",
  "emoci-",
  "energi-",
  "erar-",
  "esplor-",
  "Esperanto",
  "esprim-",
  "evolu-",
  "fabrik-",
  "faden-",
  "fajr-",
  "faktur-",
  "fam-",
  "farĉo*",
  "fart-",
  "fer-",
  "feri-",
  "fiks-",
  "fingr-",
  "flag-",
  "flav-",
  "flu-",
  "foj-",
  "foli-",
  "fond-",
  "font-",
  "fork-",
  "fos-",
  "fraz-",
  "fremd-",
  "frenez-",
  "freŝ-",
  "frost-",
  "fulm-",
  "fum-",
  "fuŝ-",
  "gaj-",
  "gajn-",
  "gas-",
  "gast-",
  "glaci-",
  "glit-",
  "gramatik-",
  "gras-",
  "gratul-",
  "gren-",
  "griz-",
  "gust-",
  "gvid-",
  "ĝarden-",
  "ĝen-",
  "ĝentil-",
  "ĝoj-",
  "ĝu-",
  "halt-",
  "har-",
  "haŭt-",
  "haven-",
  "hel-",
  "herb-",
  "ho",
  "horloĝ-",
  "hund-",
  "ideal-",
  "imag-",
  "imit-",
  "industri-",
  "insekt-",
  "instrukci-",
  "insul-",
  "inteligent-",
  "intenc-",
  "intern-",
  "interpret-",
  "intervju-",
  "ja",
  "jak-",
  "juĝ-",
  "jup-",
  "just-",
  "ĵus*",
  "kadr-",
  "kajer-",
  "kalendar-",
  "kap-",
  "kapabl-",
  "kat-",
  "kategori-",
  "kaz-",
  "kest-",
  "kilogram-",
  "kilometr-",
  "kin-",
  "kis-",
  "klak-",
  "klav-",
  "klin-",
  "klopod-",
  "kok-",
  "kol-",
  "koler-",
  "komb-",
  "koment-",
  "komfort-",
  "komitat-",
  "kompar-",
  "kompat-",
  "komput-",
  "komunik-",
  "koncern-",
  "kondiĉ-",
  "konduk-",
  "konfes-",
  "konkret-",
  "konkurs-",
  "konserv-",
  "konsider-",
  "konsist-",
  "konstant-",
  "kontribu-",
  "kontrol-",
  "konven-",
  "konvink-",
  "kopi-",
  "kor-",
  "korekt-",
  "korespond-",
  "kort-",
  "kostum-",
  "kotiz-",
  "kovert-",
  "kovr-",
  "krajon-",
  "kre-",
  "kredit-",
  "kri-",
  "kruel-",
  "krur-",
  "kudr-",
  "kuk-",
  "kuler-",
  "kulp-",
  "kur-",
  "kurac-",
  "kuraĝ-",
  "kutim-",
  "kuz-",
  "kvadrat-",
  "kvalit-",
  "kvankam",
  "kvant-",
  "kvazaŭ*",
  "lac-",
  "lamp-",
  "lang-",
  "laŭ*",
  "legom-",
  "leĝ-",
  "lert-",
  "lign-",
  "lim-",
  "lini-",
  "list-",
  "lit-",
  "liter-",
  "log-",
  "lum-",
  "lun-",
  "malgraŭ*",
  "mantel-",
  "marŝ-",
  "mastr-",
  "material-",
  "mebl-",
  "medi-",
  "mend-",
  "mens-",
  "merit-",
  "mesaĝ-",
  "metal-",
  "metr-",
  "mezur-",
  "mikrofon-",
  "miks-",
  "minus*",
  "modern-",
  "mok-",
  "mol-",
  "moment-",
  "mur-",
  "murdi*",
  "mus-",
  "muze-",
  "naĝ-",
  "naz-",
  "nebul-",
  "neĝ-",
  "nek",
  "nep-",
  "nepr-",
  "neŭtral-",
  "nev-",
  "nigr-",
  "nivel-",
  "nombr-",
  "nord-",
  "normal-",
  "not-",
  "nu",
  "nub-",
  "obe-",
  "objekt-",
  "observ-",
  "odor-",
  "okcident-",
  "okul-",
  "ole-",
  "ond-",
  "onkl-",
  "or-",
  "ord-",
  "ordon-",
  "orel-",
  "orient-",
  "original-",
  "ost-",
  "ov-",
  "pak-",
  "pantalon-",
  "par-",
  "pas-",
  "past-",
  "paŝ-",
  "paŭz-",
  "pen-",
  "pentr-",
  "perfekt-",
  "period-",
  "pez-",
  "pied-",
  "pik-",
  "pilk-",
  "plafon-",
  "plan-",
  "plank-",
  "plant-",
  "plast-",
  "plend-",
  "plor-",
  "plum-",
  "plus*",
  "po",
  "poent-",
  "poezi-",
  "polic-",
  "politik-",
  "pont-",
  "popular-",
  "pork-",
  "posed-",
  "postul-",
  "poŝ-",
  "pot-",
  "praktik-",
  "prav-",
  "precip-",
  "preciz-",
  "preĝ-",
  "preleg-",
  "prem-",
  "premi-",
  "preter*",
  "prez-",
  "princip-",
  "profesi-",
  "profund-",
  "program-",
  "progres-",
  "projekt-",
  "promen-",
  "promes-",
  "propon-",
  "prunt-",
  "pun-",
  "punkt-",
  "pup-",
  "puŝ-",
  "rad-",
  "radik-",
  "rand-",
  "rang-",
  "real-",
  "recenz-",
  "redaktor-",
  "reg-",
  "reĝ-",
  "reklam-",
  "rekt-",
  "religi-",
  "respekt-",
  "rezult-",
  "rimed-",
  "ripoz-",
  "rob-",
  "roman-",
  "rond-",
  "roz-",
  "rubrik-",
  "ruĝ-",
  "rul-",
  "saĝ-",
  "sak-",
  "sal-",
  "salt-",
  "sang-",
  "sankt-",
  "sat-",
  "sav-",
  "sek-",
  "sekretari-",
  "seks-",
  "sekur-",
  "senc-",
  "seri-",
  "serioz-",
  "sezon-",
  "sign-",
  "silent-",
  "sistem-",
  "soci-",
  "soif-",
  "soldat-",
  "solv-",
  "spac-",
  "spec-",
  "spegul-",
  "spekt-",
  "spez-",
  "spic-",
  "spir-",
  "spirit-",
  "stat-",
  "stel-",
  "strang-",
  "streĉ-",
  "stult-",
  "subit-",
  "sud-",
  "sufer-",
  "sum-",
  "supoz-",
  "ŝaf-",
  "ŝanc-",
  "ŝerc-",
  "ŝir-",
  "ŝlos-",
  "ŝnur-",
  "ŝrank-",
  "ŝtel-",
  "ŝtof-",
  "ŝton-",
  "ŝtup-",
  "ŝu-",
  "ŝut-",
  "tabul-",
  "tas-",
  "task-",
  "taŭg-",
  "teatr-",
  "tegment-",
  "teknik-",
  "tekst-",
  "teler-",
  "teori-",
  "terur-",
  "tond-",
  "tradici-",
  "traf-",
  "trakt-",
  "trankvil-",
  "tru-",
  "tuk-",
  "turism-",
  "turist-",
  "tuŝ-",
  "valid-",
  "vast-",
  "vek-",
  "venk-",
  "vent-",
  "verd-",
  "verŝ-",
  "veter-",
  "vic-",
  "vigl-",
  "vin-",
  "virtual-",
  "viŝ-",
  "vitr-",
  "vizaĝ-",
  "voĉ-",
  "vok-",
  "volv-",
];

export const prefiksojFacilaj = [
  "bo",
  "dis",
  "ek",
  "eks",
  "mis",
  "pra",
];

export const sufiksojFacilaj = [
  "aĉ",
  "ec",
  "em",
  "end",
  "er",
  "estr",
  "id",
  "ind",
  "ing",
  "ism",
  "obl",
  "on",
  "op",
  "um",

  // participoj.  la kodo havas specialan kazon por vortoj finiĝantaj
  // per "-anto", ĉar tiaj vortoj estas "tre facilaj" se la baza verbo
  // ankaŭ estas.  aliaj uzoj de participoj signifas ke la vorto povas
  // esti nur "facila" aŭ "malfacila".
  "ant",
  "at",
  "int",
  "it",
  "ont",
  "ot",
];
