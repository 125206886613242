export const vortaroMalFacilaj = [
  "adiaŭ",
  "adiaŭi",
  "adiaŭa",
  "admir-",
  "admon-",
  "agord-",
  "amator-",
  "analfabet-",
  "anas-",
  "aneks-",
  "antioksid-",
  "antologi-",
  "arest-",
  "arkeologi-",
  "ateism-",
  "ateist-",
  "atelier-",
  "atest-",
  "aŭspici-",
  "aŭtonom-",
  "avantaĝ-",
  "avar-",
  "avenu-",
  "averaĝ-",
  "avid-",
  "avokad-",
  "banan-",
  "barbar-",
  "bard-",
  "barel-",
  "barok-",
  "bazar-",
  "debat-",
  "dediĉ-",
  "dediĉe",
  "deklar-",
  "deleg-",
  "detru-",
  "dialog-",
  "diamant-",
  "diametr-",
  "dimensi-",
  "direktor-",
  "diskriminaci-",
  "distri",
  "distriĝi",
  "diven-",
  "domin-",
  "duagrad-",
  "ekologi-",
  "eksport-",
  "ekspozici-",
  "ekspresionism-",
  "eksterordinar-",
  "ekvilibr-",
  "elegant-",
  "emerit-",
  "emisi-",
  "entrepren-",
  "envi-",
  "etaĝ-",
  "etend-",
  "etik-",
  "familiar-",
  "farĉi*",
  "fier-",
  "filateli-",
  "final-",
  "forĝ-",
  "formal-",
  "format-",
  "formul-",
  "formular-",
  "forum-",
  "fosili-",
  "gastronomi-",
  "geni-",
  "graved-",
  "harmoni-",
  "horizontal-",
  "ideologi-",
  "idiom-",
  "idiot-",
  "idiotism-",
  "iluzi-",
  "indeks-",
  "indiĝen-",
  "indik-",
  "individu-",
  "infer-",
  "influ-",
  "inĝenier-",
  "insign-",
  "inspir-",
  "intelekt-",
  "intense",
  "interven-",
  "intim-",
  "invers-",
  "invest-",
  "ironi-",
  "itiner-",
  "jado",
  "jada",
  "kapel-",
  "kapitalism-",
  "kapitalist-",
  "kaprid-",
  "karapac-",
  "karavan-",
  "karbon-",
  "karesi",
  "karier-",
  "karot-",
  "karp-",
  "katalog-",
  "klasike",
  "klasikaj",
  "koleg-",
  "kolegi-",
  "kolon-",
  "koloni-",
  "kombin-",
  "komunism-",
  "komunist-",
  "koncentr-",
  "koncentrej-",
  "koncert-",
  "konfid-",
  "konfirm-",
  "konkord-",
  "konscienc-",
  "konsol-",
  "konsonant-",
  "konstat-",
  "konsum-",
  "kontrakt-",
  "koridor-",
  "kredit-",
  "kreol-",
  "kribril-",
  "kukol-",
  "kurda",
  "kurier-",
  "kvaranten-",
  "lacert-",
  "later-",
  "legend-",
  "lio",
  "liberal-",
  "liver-",
  "logike",
  "majest-",
  "manifest-",
  "maraton-",
  "marŝal-",
  "martir-",
  "meteor-",
  "meteorologi-",
  "meti-",
  "miel-",
  "milda",
  "milde",
  "miliard-",
  "misi-",
  "mister-",
  "molekul-",
  "monolog-",
  "murdo*",
  "muskol-",
  "nazi-",
  "neologism-",
  "nomad-",
  "novel-",
  "nutri",
  "nutrado",
  "oficial-",
  "oficir-",
  "olda",
  "oper-",
  "optimism-",
  "optimist-",
  "origin-",
  "pandemi-",
  "pand-",
  "panel-",
  "panoram-",
  "parad-",
  "paralel-",
  "parfum-",
  "parti-",
  "pasaĝer-",
  "pasi-",
  "pasport-",
  "pastr-",
  "patron-",
  "persistem-",
  "petal-",
  "patri-",
  "planed-",
  "portret-",
  "posten-",
  "premier-",
  "prezident-",
  "prioritat-",
  "privilegi-",
  "procent-",
  "profil-",
  "proksimum-",
  "propagand-",
  "radikal-",
  "refer-",
  "regal-",
  "reĝisor-",
  "rekorda",
  "rekordo",
  "rekorde",
  "rektor-",
  "reprezali-",
  "reprezent-",
  "resum-",
  "retro-",
  "revi",
  "salat-",
  "satir-",
  "sekret-",
  "seniluziiĝ-",
  "sesi-",
  "sever-",
  "sinu-",
  "socialism-",
  "socialist-",
  "societ-",
  "sociolog-",
  "solen-",
  "solid-",
  "solidar-",
  "sonor-",
  "sonoril-",
  "spiral-",
  "strategi-",
  "sublim-",
  "ŝirmi",
  "team-",
  "teda",
  "tede",
  "tener-",
  "tend-",
  "teolog-",
  "teori-",
  "teren-",
  "terorism-",
  "terorist-",
  "terpom-",
  "testud-",
  "tragedi-",
  "triagrad-",
  "tropik-",
  "turnir-",
  "unuagrad-",
  "venen-",
  "ventr-",
  "versi-",
  "vide-",
  "vidvin-",
  "vitrin-",
  "vokal-",
  "volont-",
  "volum-",
];