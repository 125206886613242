export const vortaroLoknomoj = [
  "Afrik-",
  "Amerik-",
  "Azi-",
  "Eŭrop-",
  "Antarkt-",
  "Afgan-",
  "Alban-",
  "Alĝeri-",
  "Andor-",
  "Angl-",
  "Angol-",
  "Antigv-",
  "Atlantik-",
  "Barbud-",
  "Argentin-",
  "Armen-",
  "Aŭstrali-",
  "Aŭstr-",
  "Azerbajĝan-",
  "Baham-",
  "Balt-",
  "Barejn-",
  "Bangladeŝ-",
  "Barat-",
  "Barbad-",
  "Belorus-",
  "Belg-",
  "Beliz-",
  "Benin-",
  "Butan-",
  "Bolivi-",
  "Bosn-",
  "Hercegovin-",
  "Bocvan-",
  "Brazil-",
  "Brit-",
  "Brunej-",
  "Bulgar-",
  "Burkin-",
  "Burund-",
  "Kaboverd-",
  "Kamboĝ-",
  "Kamerun-",
  "Kanad-",
  "Ĉad-",
  "Ĉili-",
  "Ĉin-",
  "Kolombi-",
  "Komor-",
  "Kong-",
  "Kostarik-",
  "Kroat-",
  "Kipr-",
  "Ĉeĥ-",
  "Dan-",
  "Ĝibuti-",
  "Dominik-",
  "Timor-",
  "Ekvador-",
  "Egipt-",
  "Salvador-",
  "Eritre-",
  "Eston-",
  "Etiop-",
  "Fiĝi-",
  "Filipin-",
  "Finn-",
  "Franc-",
  "Gabon-",
  "Gambi-",
  "Kartvel-",
  "German-",
  "Gana-",
  "Grek-",
  "Grenad-",
  "Gvatemal-",
  "Gvine-",
  "Bisaŭ-",
  "Gujan-",
  "Haiti-",
  "Hispan-",
  "Hondur-",
  "Hungar-",
  "Island-",
  "Hind-",
  "Indonezi-",
  "Iran-",
  "Irak-",
  "Irland-",
  "Israel-",
  "Ital-",
  "Jamajk-",
  "Japan-",
  "Jordani-",
  "Jugoslav-",
  "Kazaĥ-",
  "Kenj-",
  "Kimr-",
  "Kiribat-",
  "Kore-",
  "Kosov-",
  "Kuvajt-",
  "Kirgiz-",
  "Laos-",
  "Latv-",
  "Liban-",
  "Lesot-",
  "Liberi-",
  "Libi-",
  "Liĥtenŝtejn-",
  "Litov-",
  "Luksemburg-",
  "Madagaskar-",
  "Malavi-",
  "Malajzi-",
  "Maldiv-",
  "Mali-",
  "Malt-",
  "Maŭritani-",
  "Maŭrici-",
  "Mediterane-",
  "Meksik-",
  "Mikronezi-",
  "Moldav-",
  "Mongol-",
  "Montenegr-",
  "Marok-",
  "Mozambik-",
  "Mjanma-",
  "Namibi-",
  "Naur-",
  "Nepal-",
  "Nederland-",
  "Zeland-",
  "Nikaragv-",
  "Niĝer-",
  "Niĝeri-",
  "Makedon-",
  "Norveg-",
  "Oceani-",
  "Oman-",
  "Pacifik-",
  "Pakistan-",
  "Palaŭ-",
  "Panam-",
  "Papu-",
  "Paragvaj-",
  "Peru-",
  "Pers-",
  "Pol-",
  "Portugal-",
  "Ruman-",
  "Rus-",
  "Ruand-",
  "Kristofor-",
  "Nevis-",
  "Luci-",
  "Vincent-",
  "Grenadin-",
  "Samo-",
  "Sanmarin-",
  "Santome-",
  "Principe-",
  "Saud-",
  "Arab-",
  "Senegal-",
  "Serb-",
  "Sejŝel-",
  "Sieraleon-",
  "Singapur-",
  "Skot-",
  "Slovak-",
  "Sloven-",
  "Salomon-",
  "Somal-",
  "Sovet-",
  "Sovetuni-",
  "Srilank-",
  "Surinam-",
  "Sved-",
  "Svis-",
  "Siri-",
  "Tajvan-",
  "Taĝik-",
  "Tanzani-",
  "Taj-",
  "Togoland-",
  "Tong-",
  "Trinidad-",
  "Tobag-",
  "Tunizi-",
  "Turk-",
  "Turkmen-",
  "Tuval-",
  "Ugand-",
  "Ukrain-",
  "Uson-",
  "Urugvaj-",
  "Uzbek-",
  "Vanuatu-",
  "Vatikan-",
  "Venezuel-",
  "Vjetnam-",
  "Jemen-",
  "Zambi-",
  "Zimbabv-",

  "Amsterdamo",
  "Antverpeno",
  "Ateno",
  "Bagdado",
  "Bankoko",
  "Barcelono",
  "Belfasto",
  "Berlino",
  "Berno",
  "Bogoto",
  "Bonaero",
  "Bruselo",
  "Budapeŝto",
  "Bukareŝto",
  "Ĉikago",
  "Dako",
  "Delhio",
  "Dublino",
  "Edinburgo",
  "Florenco",
  "Ĝakarto",
  "Ĝenevo",
  "Hanojo",
  "Havano",
  "Helsinko",
  "Honkongo",
  "Istanbulo",
  "Italio",
  "Italujo",
  "Jerusalemo",
  "Kairo",
  "Kalifornio",
  "Karakaso",
  "Kebeko",
  "Kito",
  "Kopenhago",
  "Lagoso",
  "Lisbono",
  "Londono",
  "Losanĝeleso",
  "Madrido",
  "Manilo",
  "Marsejlo",
  "Melburno",
  "Milano",
  "Montreal-",
  "Montevideo",
  "Moskvo",
  "Mumbajo",
  "Novjorko",
  "Odeso",
  "Oslo",
  "Otavo",
  "Parizo",
  "Pekino",
  "Peterburgo",
  "Prago",
  "Rejkjaviko",
  "Riado",
  "Romo",
  "Roterdamo",
  "Sanfrancisko",
  "Sanmarino",
  "Sanpaŭlo",
  "Santiago",
  "Sarajevo",
  "Seulo",
  "Siberio",
  "Sicilio",
  "Sidnejo",
  "Singapuro",
  "Sofio",
  "Stokholmo",
  "Ŝanhajo",
  "Tajvano",
  "Teherano",
  "Telavivo",
  "Tibeto",
  "Tokio",
  "Torin-",
  "Toronto",
  "Ulanbatoro",
  "Vankuvero",
  "Varsovio",
  "Venecio",
  "Vieno",
  "Zuriko"
];