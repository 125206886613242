export const vortaroBezonasDifinojn = [
  "aldon-",
  "alig-",
  "aliĝ-",
  "alir-",
  "allog-",
  "alŝut-",
  "alvok-",
  "antaŭjuĝ-",
  "antaŭparol-",
  "bontrov-",
  "bonven-",
  "ĉeest-",
  "depend-",
  "deven-",
  "devig-",
  "dissend-",
  "disvolv-",
  "Ek!",
  "eksterordinar-",
  "eldir-",
  "eldon-",
  "ellern-",
  "elradikig-",
  "elsend-",
  "elstar-",
  "elŝut-",
  "elten-",
  "enkonduk-",
  "enterig-",
  "Esperanti-",
  "Esperantuj-",
  "estrar-",
  "fervoj-",
  "forĵet-",
  "forpas-",
  "frapfraz-",
  "fruktodon-",
  "glaciaĵ-",
  "ĝisost-",
  "kaŝnom-",
  "kopirajt-",
  "kromlecion-",
  "kromnom-",
  "kunlabor-",
  "kunordig-",
  "kunsid-",
  "kuntekst-",
  "kunurbaĵ-",
  "langorompil-",
  "limdat-",
  "malkovr-",
  "manlibr-",
  "mansak-",
  "mantuk-",
  "markol-",
  "memfid-",
  "memstar-",
  "mezum-",
  "necesej-",
  "orientiĝ-",
  "partopren-",
  "pastaĵ-",
  "patruj-",
  "pendum-",
  "perfort-",
  "periodaĵ-",
  "petskrib-",
  "piednot-",
  "planlingv-",
  "plumnom-",
  "postlasaĵ-",
  "poŝlegi-",
  "proksimum-",
  "provleg-",
  "puŝlev-",
  "reag-",
  "reform-",
  "registar-",
  "rekon-",
  "reprezent-",
  "respondec-",
  "retir-",
  "retumil-",
  "sakstrat-",
  "samidean-",
  "seksum-",
  "sendepend-",
  "senper-",
  "sidej-",
  "sindeten-",
  "sinsekv-",
  "sinten-",
  "spacŝip-",
  "starpunkt-",
  "subprem-",
  "subskrib-",
  "subten-",
  "subtekst-",
  "superjar-",
  "supernatur-",
  "supraĵ-",
  "ŝajnig-",
  "ŝatokup-",
  "ŝuist-",
  "tabulet-",
  "tabullud-",
  "transport-",
  "transviv-",
  "trapas-",
  "um-",
  "unuop-",
  "urbestr-",
  "vagonar-",
  "ventumil-",
  "verŝajn-",
  "vidpunkt-",
  "vivten-",
  "voĉdon-",
  "volont-",
  "vortum-"
]