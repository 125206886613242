import React from "react";
import { Grid, Link, Typography } from "@mui/material";

export const Footer = (props) => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ mt: 4 }}>
      </Grid>
    </Grid>
  );
}